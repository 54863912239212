import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import styled from 'styled-components';

import { Button } from '../../common/Button';
import SwipeIndicator from '../../common/SwipeIndicator/SwipeIndicator';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

// Custom hook for heading offset based on scroll
function useHeadingOffset(ref: React.RefObject<HTMLDivElement>, minOffset = 20, maxOffset = 155) {
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    function handleScroll() {
      if (!ref.current) return;
      const rect = ref.current.getBoundingClientRect();
      const scrolledIntoSection = -rect.top;
      const newOffset = Math.min(Math.max(scrolledIntoSection, minOffset), maxOffset);
      setOffset(newOffset);
    }
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [ref, minOffset, maxOffset]);
  return offset;
}

interface ScrollJackedProps {
  mainHeading?: string;
  subHeadings?: string[];
  paragraphs?: string[];
  buttonLabel?: string;
  buttonHref?: string;
  images?: { src: string; alt: string }[];
  // Optional prop to control scroll distance between headings
  scrollDistanceMultiplier?: number;
}

const ScrollJackedWrapper = styled.div`
  background: linear-gradient(180deg, #01e65a 0%, #04ab44 100%);
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const ScrollJackedSection = styled.div`
  position: relative;
  padding-top: 100px;
  h1 {
    margin-top: 0;
    max-width: 1160px;
    justify-self: center;
    padding-bottom: 80px;
    @media (max-width: 1700px) {
      max-width: 973px;
    }
  }
  width: 100%;
  background: #01e65a;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
`;

const PinnedSection = styled.div`
  position: relative;
  width: 100%;
  max-width: 1160px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  gap: 30px;
`;

const SectionContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  gap: 2rem;
  padding: 0 2rem;
  box-sizing: border-box;
  text-align: left;
`;

const LeftColumn = styled.div`
  grid-column: 1 / span 5;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: space-between;
  a {
    margin-top: 20px;
  }
  button.active,
  button.heading {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    text-align: left;
    width: 100%;
    font-family: Boing;
    font-size: 55px;
    font-weight: 500;
    line-height: 60px;
    color: white;
    margin: 0 0 15px;
    transition: opacity 0.3s;
  }
  .heading {
    opacity: 0.3;
  }
  .active {
    opacity: 1;
  }
`;

const RightColumn = styled.div`
  grid-column: 6 / span 7;
  display: flex;
  justify-content: center;
  width: 100%;
`;

// Desktop images container
const Images = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  background: white;
  border-radius: 32px;
  box-shadow: 0px 43px 26px 0px rgba(0, 0, 0, 0.04), 0px 19px 19px 0px rgba(0, 0, 0, 0.06),
    0px 5px 11px 0px rgba(0, 0, 0, 0.07);
  overflow: hidden;
`;

// Components for vertical masking of images
const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const MaskedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Initially mask the image from the top */
  clip-path: inset(100% 0 0 0);
`;

const MobileSection = styled.div`
  background: linear-gradient(180deg, #01e65a 0%, #04ab44 100%);
  color: white;
  padding: 60px 0;
  text-align: center;
  .--feature_heading {
    margin-bottom: 20px;
  }
`;

const MobileTitle = styled.h1`
  margin: 0 0 24px;
  text-align: center;
  padding: 0 25px;
`;

const MobileParagraphs = styled.div`
  margin-bottom: 43px;
  padding: 0 25px;
`;

const MobileImageScroller = styled.div`
  display: flex;
  overflow-x: auto;
  margin-top: 24px;
  padding-bottom: 4px;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  .subheading-image-pair {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    width: calc(100% - 50px);
    max-width: 320px;
    border-radius: 16px;
    scroll-snap-align: center;
    transform: scale(0.9);
    opacity: 0.75;
    transition: transform 0.3s;
    h2 {
      margin: 20px 0 4px;
    }
    &.active {
      transform: scale(1);
      opacity: 1;
    }
    &:first-child {
      margin-left: calc((100% - 320px) / 2);
    }
    &:last-child {
      margin-right: calc((100% - 320px) / 2);
    }
  }
  img {
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
  }
`;

export const ScrollJackedComponent: React.FC<ScrollJackedProps> = ({
  mainHeading,
  subHeadings = [],
  paragraphs = [],
  buttonLabel,
  buttonHref,
  images = [],
  scrollDistanceMultiplier = 1.2,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const subheadingRefs = useRef<HTMLDivElement[]>([]);
  const hasUserScrolledRef = useRef(false);
  const initialUpdateRef = useRef(true);
  const timelineRef = useRef<gsap.core.Timeline | null>(null);
  // Ref to ignore onUpdate while manual scroll is in progress
  const isManualScroll = useRef(false);
  // Store the previous active index to determine scroll direction
  const prevActiveIndex = useRef(activeIndex);

  // Generate a unique trigger ID for this instance
  const triggerIdRef = useRef(`scrollJackedTrigger-${Math.random().toString(36).substr(2, 9)}`);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);
  const sectionRef = useRef<HTMLDivElement>(null);
  const headingOffset = useHeadingOffset(sectionRef);

  useLayoutEffect(() => {
    if (isMobile) return;
    const totalSteps = subHeadings.length;
    if (totalSteps === 0) return;

    let ctx: gsap.Context | null = null;
    setTimeout(() => {
      // Calculate scroll distance using the multiplier
      const scrollDistance = window.innerHeight * totalSteps * scrollDistanceMultiplier;
      ctx = gsap.context(() => {
        const tl = gsap.timeline({
          scrollTrigger: {
            id: triggerIdRef.current,
            trigger: containerRef.current,
            start: 'top top',
            end: `+=${scrollDistance}`,
            pin: true,
            scrub: true,
            // Snap is removed for desktop to avoid jumpy behavior during manual scroll
            onUpdate: (self) => {
              if (isManualScroll.current) return;
              if (initialUpdateRef.current && self.progress === 1) return;
              initialUpdateRef.current = false;
              const progress = self.progress;
              let step = Math.floor(progress * totalSteps);
              if (step >= totalSteps) step = totalSteps - 1;
              setActiveIndex(step);
            },
          },
        });
        timelineRef.current = tl;
        tl.to({}, { duration: 1 });
        return () => tl.kill();
      }, containerRef);
    }, 700);
    return () => ctx?.revert();
  }, [subHeadings, isMobile, scrollDistanceMultiplier]);

  useEffect(() => {
    if (!isMobile) setActiveIndex(0);
  }, [isMobile]);

  // --- Mobile scroll logic ---
  const mobileScrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!isMobile) return;
    setActiveIndex(0);
    const scroller = mobileScrollRef.current;
    if (!scroller) return;
    function handleScroll(e: Event) {
      hasUserScrolledRef.current = true;
      const currentScroller = mobileScrollRef.current;
      if (!currentScroller) return;
      const { scrollLeft, clientWidth } = currentScroller;
      if (scrollLeft < 5) {
        setActiveIndex(0);
        return;
      }
      const center = scrollLeft + clientWidth / 2;
      let minDistance = Infinity;
      let closestIndex = 0;
      subheadingRefs.current.forEach((item, index) => {
        if (!item) return;
        const itemCenter = item.offsetLeft + item.offsetWidth / 2;
        const distance = Math.abs(center - itemCenter);
        if (distance < minDistance) {
          minDistance = distance;
          closestIndex = index;
        }
      });
      setActiveIndex(closestIndex);
    }
    const timer = setTimeout(() => {
      scroller.addEventListener('scroll', handleScroll, { passive: true });
    }, 1000);
    return () => {
      clearTimeout(timer);
      scroller.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile, images]);

  // --- Vertical Masking Animation for Desktop Images ---
  // This effect now distinguishes the direction of change.
  useEffect(() => {
    if (isMobile) return;
    const newIndex = activeIndex;
    const oldIndex = prevActiveIndex.current;
    // Loop through each image element.
    images.forEach((_, i) => {
      const el = containerRef.current?.querySelector(`.masked-image-${i}`);
      if (!el) return;
      // Handle the currently active image (coming in)
      if (i === newIndex) {
        if (newIndex > oldIndex) {
          // Scrolling forward: incoming image animates from bottom masked.
          gsap.fromTo(
            el,
            { clipPath: 'inset(100% 0 0 0)' },
            { duration: 0.5, clipPath: 'inset(0% 0 0% 0)', ease: 'power1.inOut' }
          );
        } else if (newIndex < oldIndex) {
          // Scrolling backward: incoming image animates from top masked.
          gsap.fromTo(
            el,
            { clipPath: 'inset(0% 0 100% 0)' },
            { duration: 0.5, clipPath: 'inset(0% 0 0% 0)', ease: 'power1.inOut' }
          );
        } else {
          gsap.to(el, { duration: 0.5, clipPath: 'inset(0% 0 0% 0)', ease: 'power1.inOut' });
        }
      }
      // Handle the previously active image (going out)
      if (i === oldIndex && i !== newIndex) {
        if (newIndex > oldIndex) {
          // Scrolling forward: outgoing image animates to top masked
          gsap.to(el, {
            duration: 0.5,
            clipPath: 'inset(0% 0 100% 0)',
            ease: 'power1.inOut',
          });
        } else {
          // Scrolling backward: outgoing image animates to bottom masked
          gsap.to(el, {
            duration: 0.5,
            clipPath: 'inset(100% 0 0% 0)',
            ease: 'power1.inOut',
          });
        }
      }
      // Reset any other images to be fully masked
      if (i !== newIndex && i !== oldIndex) {
        gsap.set(el, { clipPath: 'inset(100% 0 0 0)' });
      }
    });
    prevActiveIndex.current = newIndex;
  }, [activeIndex, isMobile, images]);

  // --- Mobile layout ---
  if (isMobile) {
    return (
      <MobileSection>
        {mainHeading && <MobileTitle className="--huge">{mainHeading}</MobileTitle>}
        <MobileParagraphs>
          {buttonLabel && buttonHref && (
            <Button label={buttonLabel} href={buttonHref} style="white" />
          )}
        </MobileParagraphs>
        <MobileImageScroller ref={mobileScrollRef}>
          {images.map((img, i) => (
            <div
              key={i}
              className={`subheading-image-pair ${
                (i === 0 && !hasUserScrolledRef.current) || i === activeIndex ? 'active' : ''
              }`}
              ref={(el) => {
                if (el) subheadingRefs.current[i] = el;
              }}
            >
              <img src={img.src} alt={img.alt} />
              {subHeadings[i] && <h2>{subHeadings[i]}</h2>}
              {paragraphs[i] && <span className="--feature_heading">{paragraphs[i]}</span>}
            </div>
          ))}
        </MobileImageScroller>
        <SwipeIndicator
          variant="secondary"
          containerRef={mobileScrollRef}
          totalItems={images.length}
        />
      </MobileSection>
    );
  }

  // --- Desktop layout ---
  return (
    <ScrollJackedSection ref={sectionRef}>
      <h1
        className="--huge"
        style={{
          transform: `translateY(${headingOffset}px)`,
          transition: 'transform 0.2s ease-out',
          position: 'relative',
        }}
      >
        {mainHeading}
      </h1>
      <ScrollJackedWrapper ref={containerRef}>
        <PinnedSection>
          <SectionContent>
            <LeftColumn>
              <div>
                {subHeadings.map((text, i) => (
                  <button
                    key={i}
                    className={i === activeIndex ? 'active' : 'heading'}
                    onClick={() => {
                      const totalSteps = subHeadings.length;
                      if (!containerRef.current) return;
                      const newProgress = i / (totalSteps - 1);
                      // Calculate target scroll using the multiplier.
                      const scrollDistance =
                        window.innerHeight * totalSteps * scrollDistanceMultiplier;
                      const trigger = ScrollTrigger.getById(triggerIdRef.current);
                      if (trigger) {
                        const targetScroll = trigger.start + newProgress * scrollDistance;
                        // Set manual scroll flag and animate scroll using GSAP.
                        isManualScroll.current = true;
                        gsap.to(window, {
                          duration: 0.5,
                          scrollTo: { y: targetScroll },
                          onComplete: () => {
                            isManualScroll.current = false;
                          },
                        });
                      }
                      setActiveIndex(i);
                    }}
                  >
                    {text}
                  </button>
                ))}
              </div>
              <div>
                <span className="--feature_heading">{paragraphs[activeIndex]}</span>
                <br />
                {buttonLabel && buttonHref && (
                  <Button label={buttonLabel} href={buttonHref} style="white" />
                )}
              </div>
            </LeftColumn>
            <RightColumn>
              <Images className="images-wrapper">
                {images.map((img, i) => (
                  <ImageWrapper key={i} style={{ zIndex: i === activeIndex ? 2 : 1 }}>
                    <MaskedImage
                      src={img.src}
                      alt={img.alt}
                      className={`masked-image-${i} ${i === activeIndex ? 'active' : ''}`}
                    />
                  </ImageWrapper>
                ))}
              </Images>
            </RightColumn>
          </SectionContent>
        </PinnedSection>
      </ScrollJackedWrapper>
    </ScrollJackedSection>
  );
};
