import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import iconCircleTick from 'stile-shared/assets/icons/icon_circle_tick.svg';

import { Button } from 'stile-shared/src/components/2024/common/Button';
import Hearts from 'stile-shared/src/components/2024/common/Hearts';
import { TopSection } from 'stile-shared/src/components/2024/layout/RegionBasedTopSection';
import ReviewsSlider from 'stile-shared/src/components/2024/layout/ReviewsSlider/ReviewsSlider';

import { PageLayout } from 'templates/2024/PageLayout';

import AlignedSection from 'components/blocks/states/AlignedSection';
import ExpertAssistance from 'components/blocks/states/ExpertAssistance';
import HearFromSection from 'components/blocks/states/HearFromSection';
import RealWorldSection from 'components/blocks/states/RealWorldSection';
import ScienceTestSection from 'components/blocks/states/ScienceTestSection';

export { Head } from 'templates/2024/Head';

const NorthCarolinaPageLayout = styled(PageLayout)`
  .header-wrapper {
    background-color: var(--stile-grey-1);
  }
`;

const NorthCarolinaTopSection = styled(TopSection)`
  background: var(--stile-grey-1);
  .content {
    padding-bottom: 130px;

    @media (max-width: 768px) {
      padding-bottom: 0;
    }
    .--right {
      width: 50%;
      .gatsby-image-wrapper {
        border-radius: 32px;
        aspect-ratio: 1;
      }

      @media (max-width: 768px) {
        left: -25px;
        right: 0;
        height: 390px;
        width: calc(100% + 50px);

        position: relative;
        margin-top: 35px;
        overflow: hidden;

        .gatsby-image-wrapper {
          height: 390px;
          width: calc(100% + 20px);
          border-radius: 0;
          margin-top: 0;
          aspect-ratio: default;
        }
      }
    }

    .underline {
      margin-top: 0px;
      margin-bottom: 10px;
    }
  }
`;

const NorthCarolinaScienceTestSection = styled<typeof ScienceTestSection>(ScienceTestSection)`
  .--side-by-side {
    .--left,
    .--right {
      .--rounded-heading {
        // position it top center but half way up itself
        top: 8% !important;
        left: 40% !important;
        transform: translate(-30%, -150%) !important;
      }
    }

    .--left {
      .--rounded-heading {
        @media (max-width: 768px) {
          left: 30% !important;
          transform: translate(-20%, -150%) !important;
        }
      }
    }
  }
`;
const ReviewsComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .splide {
    &:nth-child(2) {
      .splide__track {
        padding-bottom: 72px;
        @media (max-width: 768px) {
          padding-bottom: 60px;
        }
      }
    }
  }
  .hearts,
  .hearts-container {
    position: relative;
    overflow: visible;
    width: 100%;
    max-width: 1160px;
    @media (max-width: 1220px) {
      padding: 0 60px;
    }
    @media (max-width: 768px) {
      padding: 0 25px;
    }
    &:last-child {
      div {
        &:last-child {
          right: 25px;
          top: -30px;
        }
      }
    }
  }
  .hearts {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    h6 {
      text-align: center;
      color: var(--stile-grey-6);
      margin: 0 20px 40px;
      @media (max-width: 768px) {
        margin-top: 60px;
      }
    }
  }
  .quote {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 21px !important;

    @media (max-width: 768px) {
      line-height: 18px !important;
    }
  }
`;
const reviews = [
  {
    name: 'Amanda',
    school: 'Bethel Middle School',
    quote:
      "One of the biggest pieces of feedback I've gotten from admin is that they've seen a noticeable, shift in classroom management, and the kids are engaged.",
  },
  {
    name: 'Suzanna',
    school: 'Enka Middle School',
    quote:
      'I am thrilled, I have been creating so much by hand this year and I am so excited to customize this resource for my students!!',
  },
  {
    name: 'Karma',
    school: 'Bethel Middle School',
    quote:
      "I've really enjoyed using Stile, I'm excited about it. It has helped me, better manage my personal time. I'm not at school for three and four extra hours a day, which is always nice. I love that.",
  },
  {
    name: 'Dylan',
    school: 'Canton Middle School',
    quote:
      "Sitle gives me that ability to give instant feedback. And feedback is where children grow. And if I can give that feedback immediately, then I'm expediting that growth.",
  },
  {
    name: 'Keesha',
    school: 'NC Advisory Board',
    quote:
      'Students are not confined to the SEP that is within a specific standard--students will be challenged with multiple SEPs within a standard, This is RIGOR!',
  },
  {
    name: 'Casey',
    school: 'Canton Middle School',
    quote:
      "It's intuitive. It's easy to see exactly what should be done on that day and how a teacher should interpret that information, how they use it, and how they implement that in a classroom.",
  },
  {
    name: 'Jill',
    school: 'Haywood County Schools',
    quote:
      'The platform engages students and I think that is really powerful. For experienced teachers, for new teachers—you give them absolute support. It gives them some comfort of where to start.',
  },
  {
    name: 'Kyle',
    school: 'Lincoln County Schools',
    quote:
      'I love how I can see that you are all working on the same thing at the same time and I can identify misconceptions and who gets it and who needs more support.',
  },
  {
    name: 'Blaine',
    school: 'Canton Middle School',
    quote:
      "It's been a smooth transition from trying to plan out each lesson every day to just being able to give the lessons, especially being brand new to science.",
  },
  {
    name: 'Brittany',
    school: 'Mooresville Graded School District',
    quote: 'I got the highest scores in the district! Thank you Stile!!',
  },
];

function TwentyTwentyFourOregonPage(props: PageProps) {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);
  return (
    <NorthCarolinaPageLayout {...props}>
      <NorthCarolinaTopSection>
        <div className="content">
          <div className="--left">
            <h1>Built for the new North Carolina standards</h1>
            <h6 className="h7">
              The changes to the North Carolina Science Standards are big, bold, and very… NC.
              <br />
              Stile&apos;s got you.
            </h6>
            <ul>
              <li>
                <img src={iconCircleTick} alt="bullet point" />{' '}
                <h6 className="h7">Built for the new NC standards.</h6>
              </li>
              <li>
                <img src={iconCircleTick} alt="bullet point" />{' '}
                <h6 className="h7">
                  The only middle school curriculum approved by the DPI for 6th, 7th and 8th grades.
                </h6>
              </li>
            </ul>
          </div>
          <div className="--right">
            <StaticImage
              src="../../../../stile-shared/assets/images/states/north-carolina/top-image.jpg"
              alt="North Carolina Science Standards"
              placeholder="none"
              loading="eager"
            />
          </div>
        </div>
        <ReviewsComponent>
          <div className="hearts" style={{ position: 'relative' }}>
            <h6>What North Carolina educators are saying about Stile</h6>
          </div>
          <div className="hearts-container">
            <Hearts />
          </div>
          <ReviewsSlider
            header="What North Carolina educators are saying about Stile"
            reviews={reviews}
          />
          {isMobile && (
            <div className="hearts-container">
              <Hearts />
            </div>
          )}
        </ReviewsComponent>
      </NorthCarolinaTopSection>

      <AlignedSection
        heading="Fully aligned and completely customizable"
        body={
          <>
            There&apos;s &apos;alignment.&apos; And then there&apos;s actual alignment. Thanks to
            Stile&apos;s powerful and customizable platform, we took the very best of our existing
            middle school science curriculum and blended it with brand-new lessons tailored for
            North Carolina. The result is a seamless, structured, and sequenced Middle School
            Science Curriculum for the state.
            <br />
            <br />
            It&apos;s no wonder that Stile was{' '}
            <strong>approved by the NCDPI for 6th, 7th, and 8th grades.</strong>
          </>
        }
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/states/north-carolina/standards.jpg"
            alt="image of north carolina"
          />
        }
        buttons={[
          <Button
            key={0}
            href="/states/north-carolina-alignment"
            size="small"
            label="View alignment to North Carolina’s standards"
            className="--alignment"
          />,
        ]}
      />

      <HearFromSection
        heading="Hear from Haywood County, NC"
        subheading="Stile is not only helping Haywood County teachers transition to the new NC standards but it's saving hours of teacher lesson preparation time and boosting ELA skills in the science classroom."
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/states/north-carolina/video.png"
            alt="thumbnail from a video"
          />
        }
        youtubeUrl="https://www.youtube.com/watch?v=sJ3Xnio77uk"
      />

      <RealWorldSection
        heading="Real-world phenomena from North Carolina’s backyard"
        subheading="Many of Stile's phenomena are drawn from wonders all around the world. After all,
          it's important that students grow into global citizens. But sometimes, there's
          nothing more fascinating than investigating phenomena right where you live. Stile's
          curriculum for North Carolina offers a balanced blend of local and global phenomena."
        tabs={[
          'Lake Norman algae',
          'Venus flytrap investigation',
          'North Carolina food web',
          'Charlotte gold rush',
        ]}
        headings={[
          'The Lake Norman algae mystery',
          'The wonders of the Venus flytrap',
          'Tracing energy through local food webs',
          'Unearthing chemistry in North Carolina’s first gold rush',
        ]}
        paragraphs={[
          'In our Water Safety unit, students dive into the mystery of blue-green algae blooms in Lake Norman, Charlotte, analyzing water quality reports to find out if the lake is safe for swimming (or, you know, playing Marco Polo). Talk about making a splash in science!',
          'Students explore the Venus flytrap, North Carolina’s very own carnivorous marvel, in our Plant Adaptations unit. They get hands-on to uncover what makes this rare plant snap shut and how it captures its prey. It’s a bite of science they won’t forget!',
          'Local ecosystems come to life in our Food Webs unit. Students build models that trace the flow of energy through North Carolina’s food chains, discovering how the intricate balance of nature is maintained in their own very backyard.',
          'The Periodic Table unit takes students back to Charlotte’s historic gold rush, where they unearth the chemistry of elements and compounds. They dig deep (get it?) to discover how the gold hunt shaped the city’s history while putting their chemistry skills to the test.',
        ]}
        images={[
          <StaticImage
            key={0}
            src="../../../../stile-shared/assets/images/states/north-carolina/slider-1.png"
            alt="feedback"
          />,
          <StaticImage
            key={1}
            src="../../../../stile-shared/assets/images/states/north-carolina/slider-2.png"
            alt="feedback"
          />,
          <StaticImage
            key={2}
            src="../../../../stile-shared/assets/images/states/north-carolina/slider-3.png"
            alt="feedback"
          />,
          <StaticImage
            key={3}
            src="../../../../stile-shared/assets/images/states/north-carolina/slider-4.png"
            alt="feedback"
          />,
        ]}
      />

      <NorthCarolinaScienceTestSection
        heading="Prepare your students for the North Carolina Science Test"
        subHeading="The North Carolina End-of-Grade science test aims to measure students' science knowledge
            and critical thinking ability. Stile’s curriculum precisely scaffolds both of these,
            providing students with plenty of opportunities to practice and excel on the North
            Carolina Science Test."
        leftHeading="North Carolina Science Test"
        leftContent={
          <>
            Which statement is true about a chemical reaction?
            <ol>
              <li>The physical states of the atoms in the reactants determine the products.</li>
              <li>Some of the atoms in the reactants will be part of the products.</li>
              <li>The number of atoms in the reactants determines the products.</li>
              <li>All of the atoms in the reactants will be a part of the products.</li>
            </ol>
          </>
        }
        rightHeading="Stile Science Test"
        rightContent={
          <>
            A chemical reaction can be used to turn water into two gases: hydrogen and oxygen.
            Determine which of the following statements about this reaction are true.
            <ol>
              <li>Hydrogen is a reactant</li>
              <li>Water is a reactant</li>
              <li>A compound is converted into two elements</li>
              <li>An element is converted into two compounds</li>
            </ol>
          </>
        }
      />

      <ExpertAssistance
        heading="Pilot Stile with Charlotte’s own expert, Mark Picardo"
        copy=" Stile is all about empowering teachers with the best support. In the Carolinas,
                  that means teaming up with Mark Picardo, a seasoned educator with extensive
                  experience in teaching, administration, and instructional coaching. Together,
                  teachers are supported (and lessons are supercharged!)."
        href="/why-choose-stile/oregon-pilot"
        expertImage={
          <StaticImage
            src="../../../../stile-shared/assets/images/states/north-carolina/expert.jpg"
            alt="Mark Picardo"
            quality={100}
            height={1250}
          />
        }
      />
    </NorthCarolinaPageLayout>
  );
}

export default TwentyTwentyFourOregonPage;
